h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-block-start: unset;
  /* margin-block-end: unset; */
  margin-inline-start: unset;
  margin-inline-end: unset;
  font-weight: unset;
}

p:last-child {
  margin-block-end: unset;
}

figure,
ul {
  margin-block-start: unset;
  margin-block-end: unset;
  margin-inline-start: unset;
  margin-inline-end: unset;
  padding-inline-start: unset;
}
